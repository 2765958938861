<template>
  <div>
    <v-navigation-drawer
      :value="isDrawerOpen"
      app
      floating
      width="310"
      class="app-navigation-menu"
      :right="$vuetify.rtl"
      @input="val => $emit('update:is-drawer-open', val)"
    >
      <!-- Navigation Header -->
      <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img
            :src="require('@/assets/images/logos/main-logo.png')"
            max-height="250px"
            max-width="250px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand shaped class="vertical-nav-menu-items pr-5">
        <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }"
                       :icon="icons.mdiHomeOutline"></nav-menu-link>
        <nav-menu-link
          v-if="position === 'CASHIER'"
          title="(Send/Receive Money) Remittance"
          :to="{ name: 'new-gl-remittance' }"
          :icon="icons.mdiBankTransfer"
        ></nav-menu-link>
        <nav-menu-section-title
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="ADMIN INTERFACE"
        ></nav-menu-section-title>
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="For Approval Transaction"
          :to="{ name: 'approve-transaction-branch' }"
        ></nav-menu-link>
<!--        <nav-menu-link-->
<!--          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"-->
<!--          title="Approved Request"-->
<!--          :to="{ name: 'approve-request-fund' }"-->
<!--        ></nav-menu-link>-->
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Register Branch"
          :to="{ name: 'admin-register-branch' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'"
          title="Register User"
          :to="{ name: 'admin-register-users' }"
        ></nav-menu-link>
        <nav-menu-section-title
          v-if="position === 'CASHIER'"
          title="CASHIER INTERFACE"
        ></nav-menu-section-title>
        <!--      Liquidations-->
<!--        <nav-menu-link v-if="position === 'CASHIER'" title="Fund Request"-->
<!--                       :icon="icons.mdiAccountCash"-->
<!--                       :to="{ name: 'fund-request' }"></nav-menu-link>-->
        <nav-menu-link v-if="position === 'CASHIER'" title="CorePortal Transaction"
                       :icon="icons.mdiCashLock"
                       :to="{ name: 'fund-liquidations' }"></nav-menu-link>
        <nav-menu-link v-if="position === 'CASHIER'" title="Deposit Slip"
                       :icon="icons.mdiCash100"
                       :to="{ name: 'deposit-liquidations' }"></nav-menu-link>
        <!--      Liquidations End-->
      </v-list>
      <nav-menu-section-title
        title="VIEW INTERFACE"
      ></nav-menu-section-title>
<!--      <nav-menu-link title="Account Statement"-->
<!--                     :icon="icons.mdiChartBar"-->
<!--                     :to="{ name: 'account-statement' }"></nav-menu-link>-->
      <nav-menu-link title="Deposit Slips Data"
                     :icon="icons.mdiFileOutline"
                     :to="{ name: 'deposit-slips-data' }"></nav-menu-link>
      <nav-menu-link v-if="position === 'ADMIN' || position === 'ADMIN SPECIAL'" title="Bank Transaction Summary"
                     :icon="icons.mdiBankTransfer"
                     :to="{ name: 'bank-transaction-summary' }"></nav-menu-link>
    </v-navigation-drawer>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiHomeOutline,
    mdiAlphaTBoxOutline,
    mdiEyeOutline,
    mdiCreditCardOutline,
    mdiTable,
    mdiFileOutline,
    mdiFormSelect,
    mdiAccountCogOutline,
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiForum,
    mdiCashMultiple,
    mdiBankTransfer,
    mdiReceipt,
    mdiPaperRollOutline,
    mdiCash100,
    mdiBank,
    mdiCashLock,
    mdiPhonePlus,
    mdiCreditCardCheck,
    mdiHumanQueue,
    mdiHumanCapacityIncrease,
    mdiChartMultiline,
    mdiChartTimeline,
    mdiCashRemove,
    mdiShoePrint,
    mdiCurrencyPhp,
    mdiFormatListText,
    mdiClipboardListOutline,
    mdiMonitorDashboard,
    mdiBookOpenVariant,
    mdiAccountCash,
    mdiChartBar,
  } from '@mdi/js'
  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'
  import {mapGetters} from 'vuex'

  export default {
    components: {
      NavMenuSectionTitle,
      NavMenuGroup,
      NavMenuLink,
    },
    computed: {
      ...mapGetters('authentication', ['position']),
    },
    props: {
      isDrawerOpen: {
        type: Boolean,
        default: null,
      },
    },
    setup() {
      return {
        icons: {
          mdiHomeOutline,
          mdiAlphaTBoxOutline,
          mdiEyeOutline,
          mdiCreditCardOutline,
          mdiTable,
          mdiFileOutline,
          mdiFormSelect,
          mdiAccountCogOutline,
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiForum,
          mdiCashMultiple,
          mdiBankTransfer,
          mdiReceipt,
          mdiPaperRollOutline,
          mdiCash100,
          mdiBank,
          mdiCashLock,
          mdiPhonePlus,
          mdiCreditCardCheck,
          mdiHumanQueue,
          mdiHumanCapacityIncrease,
          mdiChartMultiline,
          mdiChartTimeline,
          mdiCashRemove,
          mdiShoePrint,
          mdiCurrencyPhp,
          mdiFormatListText,
          mdiClipboardListOutline,
          mdiMonitorDashboard,
          mdiBookOpenVariant,
          mdiAccountCash,
          mdiChartBar,
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;

    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }

  // You can remove below style
  // Upgrade Banner
  .app-navigation-menu {
    .upgrade-banner {
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
