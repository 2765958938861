<template>
  <div>
    <v-menu offset-y left nudge-bottom="14" min-width="230"
            content-class="user-profile-menu-content">
      <template v-slot:activator="{ on, attrs }">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
            <v-avatar size="40px">
              <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar>
          </v-badge>
          <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
            <span class="text--primary font-weight-semibold mb-n1">
              {{ name }}
            </span>
            <small class="text--disabled text-capitalize">{{ position }}</small>
          </div>
        </div>

        <v-divider></v-divider>
        <v-list-item link v-if="position==='CASHIER'  && date_is_can_new_month"
                     @click="start_new_month_transaction">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiReload }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Start New Month Transaction</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Profile -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Email -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiEmailOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inbox</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Chat -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiChatOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Chat</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-badge inline color="error" content="2"></v-badge>
          </v-list-item-action>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Settings -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Pricing -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pricing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- FAQ -->
        <v-list-item link>
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiEmailOutline,
    mdiCheckboxMarkedOutline,
    mdiChatOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
  } from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'
  import moment from 'moment'

  export default {
    data() {
      return {
        show: false,
        txt: '',
        date_is_can_new_month: false,

        icons: {
          mdiChatOutline,
          mdiCogOutline,
          mdiHelpCircleOutline,
          mdiLogoutVariant,
        },
      }
    },
    mounted() {
      this.conditions_for_new_month()
    },
    watch: {
      month_end: function () {
        this.conditions_for_new_month()
      },
    },
    computed: {
      ...mapGetters('authentication', ['id_no', 'name', 'position', 'branch_id', 'branch', 'month_of','month_end']),
    },
    methods: {
      ...mapActions('authentication', ['logout_credentials']),
      ...mapActions('transaction_months', ['start_new_transaction_month']),
      conditions_for_new_month() {
        var end_date = moment(this.month_end, 'MMMM DD, YYYY')
        if (moment(end_date).isBefore(moment())) {
          this.date_is_can_new_month = true
        }
      },
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no)
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
      start_new_month_transaction() {
        var start = moment().clone().startOf('month').format('MMMM DD, YYYY')
        var end = moment().clone().endOf('month').format('MMMM DD, YYYY')
        const data2 = new FormData()
        data2.append('month_of', moment().format('MMMM YYYY'));
        data2.append('year', moment().year());
        data2.append('month_start', start);
        data2.append('month_end', end);
        data2.append('branch_id', this.branch_id);
        var time = 5000;
        this.show = true
        this.txt = 'STARTING NEW MONTH TRANSACTIONS...'
        this.start_new_transaction_month(data2)
          .then(response => {
            if (response.status === 201) {
              time = 10000;
              this.txt = response.data
            } else {
              this.txt = response.data + ' AND AUTOMATICALLY LOGOUT!'
              this.logout()
            }
          })
          .catch(error => {
            console.log(error)
          })
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.txt = ''
          this.show = false
        }, time)
      }
    },
  }
</script>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
