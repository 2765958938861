import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pages/login',
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/pages/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },
  {
    path: '/new-gl-remittance',
    name: 'new-gl-remittance',
    component: () => import('@/views/others/Remittance'),
    meta: {
      title: 'Remittance',
      requiresAuth: true,
      requiresCashier: true,
    },
  },
  {
    path: '/fund-liquidations',
    name: 'fund-liquidations',
    component: () => import('@/views/liquidations/SporoFundLiquidation'),
    meta: {
      title: 'Sporo Fund Liquidations',
      requiresAuth: true,
      requiresCashier: true,
    },
  },
  {
    path: '/fund-request',
    name: 'fund-request',
    component: () => import('@/views/request/RequestFund'),
    meta: {
      title: 'Request Chapel Fund',
      requiresAuth: true,
      requiresCashier: true,
    },
  },
  {
    path: '/fund-summary',
    name: 'fund-summary',
    component: () => import('@/views/reports_interface/FundSummary'),
    meta: {
      title: 'Callers Fund Summary',
      requiresAuth: true,
    },
  },
  {
    path: '/admin-register-users',
    name: 'admin-register-users',
    component: () => import('@/views/admin_interface_special/NewUser'),
    meta: {
      title: 'New User',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/approve-request-fund',
    name: 'approve-request-fund',
    component: () => import('@/views/admin_interface/ApprovedRequestFund'),
    meta: {
      title: 'Approved Request Fund',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/approve-transaction-branch',
    name: 'approve-transaction-branch',
    component: () => import('@/views/admin_interface_special/ApprovedBranchRequestTransaction'),
    meta: {
      title: 'Approved Branch Transaction',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-client',
    name: 'new-client',
    component: () => import('@/views/register/NewClient'),
    meta: {
      title: 'New Client',
      requiresAuth: true,

    },
  },
  {
    path: '/admin-register-branch',
    name: 'admin-register-branch',
    component: () => import('@/views/admin_interface_special/NewBranch'),
    meta: {
      title: 'New Branch',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/deposit-liquidations',
    name: 'deposit-liquidations',
    component: () => import('@/views/deposit_slip/Liquidation'),
    meta: {
      title: 'Deposit Slip',
      requiresAuth: true,
      requiresCashier: true,
    },
  },
  {
    path: '/deposit-slips-data',
    name: 'deposit-slips-data',
    component: () => import('@/views/view_interface/Remittances'),
    meta: {
      title: 'Deposit Slips Data',
      requiresAuth: true,
    },
  },
  {
    path: '/account-statement',
    name: 'account-statement',
    component: () => import('@/views/view_interface/AccountStatement'),
    meta: {
      title: 'Account Statement',
      requiresAuth: true,
    },
  },
  {
    path: '/bank-transaction-summary',
    name: 'bank-transaction-summary',
    component: () => import('@/views/view_interface/BankTransactionSummary'),
    meta: {
      title: 'Bank Transaction Summary',
      requiresAuth: true,
      requiresAdmin: true,
      requiresManager: true,
    },
  },

]

const router = new VueRouter({
  // mode: "history",
  routes,
})

export default router
