import axios from 'axios'

axios.defaults.withCredentials = true

const actions = {
  search_by_reference_no({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/search-by-reference_no', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  save_pera_padala({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/save-pera-padala', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  save_pera_kuha({rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/save-pera-kuha', data)
        .then(response => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
}
